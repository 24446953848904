'use client';

import { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Link from 'next/link';
import { AlertTriangle, RefreshCw } from 'lucide-react';
import { useTranslations } from 'next-intl';
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  const [isHovering, setIsHovering] = useState(false);
  const controls = useAnimation();
  const t = useTranslations('Error');
  useEffect(() => {
    console.error(error);
  }, [error]);
  useEffect(() => {
    document.body.style.backgroundColor = '#f0f0f0';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  useEffect(() => {
    controls.start({
      rotate: [0, 5, -5, 5, 0],
      transition: {
        duration: 0.5,
        loop: Infinity,
        repeatDelay: 3
      }
    });
    return () => {
      controls.stop();
    };
  }, [controls]);
  const iconVariants = {
    hover: {
      scale: 1.2,
      rotate: 360,
      transition: {
        duration: 0.5
      }
    },
    tap: {
      scale: 0.8,
      rotate: -360,
      transition: {
        duration: 0.5
      }
    }
  };
  return <div className="min-h-screen flex items-center justify-center bg-gray-100 text-gray-800 overflow-hidden" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <motion.div className="text-center space-y-6 relative z-10" initial={{
      opacity: 0,
      y: -50
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.5
    }} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
        <motion.div className="text-9xl font-bold relative" animate={controls} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
          <motion.div whileHover="hover" whileTap="tap" variants={iconVariants} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
            <AlertTriangle className="w-32 h-32 text-yellow-500 mx-auto" data-sentry-element="AlertTriangle" data-sentry-source-file="error.tsx" />
          </motion.div>
        </motion.div>

        <motion.h2 className="text-3xl font-semibold" initial={{
        opacity: 0,
        x: -50
      }} animate={{
        opacity: 1,
        x: 0
      }} transition={{
        delay: 0.2,
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
          {t('title')}
        </motion.h2>

        <motion.p className="text-gray-600 max-w-md mx-auto" initial={{
        opacity: 0,
        x: 50
      }} animate={{
        opacity: 1,
        x: 0
      }} transition={{
        delay: 0.4,
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
          {t('message')}
        </motion.p>

        <motion.div className="space-x-4" initial={{
        opacity: 0,
        y: 50
      }} animate={{
        opacity: 1,
        y: 0
      }} transition={{
        delay: 0.6,
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
          <motion.button onClick={reset} className="inline-block bg-yellow-500 text-white px-6 py-3 rounded-md font-semibold transition-all" whileHover={{
          scale: 1.05,
          backgroundColor: '#eab308'
        }} whileTap={{
          scale: 0.95
        }} onHoverStart={() => setIsHovering(true)} onHoverEnd={() => setIsHovering(false)} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
            <span className="flex items-center">
              {t('tryAgain')}
              <motion.span animate={{
              rotate: isHovering ? 360 : 0
            }} transition={{
              duration: 0.5
            }} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
                <RefreshCw className="ml-2 w-5 h-5" data-sentry-element="RefreshCw" data-sentry-source-file="error.tsx" />
              </motion.span>
            </span>
          </motion.button>
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="error.tsx">
            <motion.span className="inline-block bg-slate-900 text-white px-6 py-3 rounded-md font-semibold" whileHover={{
            scale: 1.05,
            backgroundColor: '#4b5563'
          }} whileTap={{
            scale: 0.95
          }} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
              {t('goHome')}
            </motion.span>
          </Link>
        </motion.div>
      </motion.div>

      <motion.div className="absolute inset-0 z-0 top-24" initial={{
      opacity: 0
    }} animate={{
      opacity: 1
    }} transition={{
      delay: 1,
      duration: 1
    }} data-sentry-element="unknown" data-sentry-source-file="error.tsx">
        {[...Array(20)].map((_, i) => <motion.div key={i} className="absolute bg-yellow-300 rounded-full" style={{
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        width: `${Math.random() * 20 + 5}px`,
        height: `${Math.random() * 20 + 5}px`
      }} initial={{
        scale: 0,
        opacity: 0
      }} animate={{
        scale: [0, 1, 1, 0],
        opacity: [0, 0.7, 0.7, 0],
        y: [0, -30, -60, -100]
      }} transition={{
        duration: Math.random() * 3 + 2,
        repeat: Infinity,
        repeatDelay: Math.random() * 3
      }} />)}
      </motion.div>
    </div>;
}